@import "../../../utils/variables/variables.scss";
.professionalPackages {
  padding-bottom: 80px;
  .containerGridAll {
    // background-color: $subColorBlue;
    // border-radius: 6px;
    // -webkit-border-radius: 6px;
    // -moz-border-radius: 6px;
    // -ms-border-radius: 6px;
    // -o-border-radius: 6px;
    overflow: hidden;
    margin-bottom: 50px;
    padding: 0 100px;
    .containerImgRight {
      img {
        width: 100%;
        height: 100%;
        //   max-height: 240px;
        object-fit: cover;
        object-position: center;
      }
      .left {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        -webkit-border-bottom-left-radius: 6px;
        -webkit-border-top-left-radius: 6px;
        -moz-border-bottom-left-radius: 6;
        -moz-border-top-left-radius: 6px;
        -ms-border-bottom-left-radius: 6px;
        -ms-border-top-left-radius: 6px;
        -o-border-bottom-left-radius: 6px;
        -o-border-top-left-radius: 6px;
      }
      .right {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -webkit-border-top-right-radius: 6px;
        -moz-border-bottom-right-radius: 6;
        -moz-border-top-right-radius: 6px;
        -ms-border-bottom-right-radius: 6px;
        -ms-border-top-right-radius: 6px;
        -o-border-bottom-right-radius: 6px;
        -o-border-top-right-radius: 6px;
      }
    }
    .left {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      -webkit-border-bottom-left-radius: 6px;
      -webkit-border-top-left-radius: 6px;
      -moz-border-bottom-left-radius: 6;
      -moz-border-top-left-radius: 6px;
      -ms-border-bottom-left-radius: 6px;
      -ms-border-top-left-radius: 6px;
      -o-border-bottom-left-radius: 6px;
      -o-border-top-left-radius: 6px;
      text-align: left;
      background: rgba($color: #404040, $alpha: 0.06);
    }
    .right {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      -webkit-border-bottom-right-radius: 6px;
      -webkit-border-top-right-radius: 6px;
      -moz-border-bottom-right-radius: 6;
      -moz-border-top-right-radius: 6px;
      -ms-border-bottom-right-radius: 6px;
      -ms-border-top-right-radius: 6px;
      -o-border-bottom-right-radius: 6px;
      -o-border-top-right-radius: 6px;
      text-align: left;
      background: rgb(74, 110, 232);
      background: linear-gradient(
        270deg,
        rgba(20, 43, 115, 1) 0%,
        rgba(74, 110, 232, 1) 100%
      );
    }

    .containerData {
      padding: 75px 32px 70px;

      .bookBtn a {
        // font-size: 18px;
        // padding: 15px 12px;
        //Coming soon
        padding: 18px 12px;
        font-size: 12px;
        pointer-events: none;
        //Coming soon
        font-family: $DIN-bold;
        text-decoration: none;
        color: $white;
        display: inline-block;
        text-align: center;
        width: 100%;
        border-radius: $Radius;
        border: solid 2px;
        transition: all ease 0.2s;
        margin-top: 25px;
      }
      .bookBtn a:hover {
        background-color: $white;
        color: $subColorBlue;
      }
      p,
      ul {
        margin: 0;
        // color: $white;
      }
      .Header {
        p {
          font-size: 32px;
          line-height: 38px;
          font-family: $FBT-heavy;
          margin: 0;
          text-transform: uppercase;
          padding: 0;
          margin-bottom: 50px;
          color: $white;
        }
        .blue {
          color: $subColorBlue !important;
          margin: 0;
        }
      }
      .subTitle {
        p {
          color: $mainDarkGray;
          font-size: 24px;
          font-family: $DIN-bold;
          line-height: 32px;
          padding: 40px 0;
        }
      }
      .description {
        margin-bottom: 50px;
        a {
          font-size: 14px !important;
          font-family: $DIN-bold;
          text-decoration: none;
          color: $white;
          display: inline-block;
          text-align: center;
          width: 97%;
          padding: 12px;
          border-radius: $Radius;
          border: solid 2px;
          transition: all ease 0.2s;
          margin-top: 5px;
        }
        a:hover {
          background-color: $white;
          color: $subColorBlue;
        }
        p {
          font-size: 22px;
          font-family: $DIN-regular;
          // margin-bottom: 15px;
          color: $white;
          margin: 0;
          line-height: 28px;
          padding: 0;

          // margin-bottom: 15px;
          display: inline-block;
        }
        .black {
          color: #000;
        }
        ul {
          list-style: none;
          padding: 0 0 0 18px;
          margin-bottom: 18px;
          li {
            font-size: 18px;
            line-height: 20px;
            font-family: $DIN-regular;
            margin-bottom: 8px;
            &::before {
              content: "";
              display: inline-block;
              border: 1px solid $white;
              margin-left: -1em;
              margin-right: 10px;
              width: 5px;
            }
          }
        }
        .listHeader {
          margin-bottom: 20px;
        }
      }
      .PackagePerPerson {
        .Header {
          p {
            font-size: 45px;
            font-family: $DIN-bold;
            line-height: 60px;
            margin: 0;
            text-align: end;
            // text-transform: uppercase;
          }

          span {
            font-family: $FBT-medium;
            font-size: 20px;
            line-height: 27px;
          }
          .register {
            color: $white;
            background: $subColorBlue;
            // background: $subGray;
            // pointer-events: none;
          }
          a {
            display: inline-block;
            text-align: center;
            background: #fff;
            border-radius: 5px;
            color: $mainBlue;
            text-decoration: none;
            font-family: $FBT-heavy;
            // padding: 18px 50px;
            width: 63%;
            height: 51px;
            font-size: 18px;
            line-height: 51px;
            text-transform: uppercase;
            //   margin-top: 8vh;
            border: none;
          }
        }
        .description {
          text-align: center;
          p {
            font-size: 38px;
            font-family: $FBT-heavy;
            line-height: 60px;
            color: $white;
            span {
              font-size: 18px;
              font-family: $FBT-medium;
              line-height: 24px;
            }
          }
          .OR {
            font-size: 28px;
            font-family: $FBT-heavy;
            line-height: 42px;
          }
          > div {
            > div {
              text-align: initial;
            }
          }
        }
      }
      .header2 {
        text-align: center;
        margin-bottom: 22px;
        h2 {
          font-size: 28px;
          line-height: 37px;
          font-family: "D-DIN-Bold";
          margin: 0 0 5px 0;
          text-transform: uppercase;
          color: $white;
        }
        p {
          font-size: 18px;
          line-height: 24px;
          font-family: $DIN-regular;
          margin: 0;
          text-align: left;
          white-space: break-spaces;
        }
      }
    }
  }
  .containerGridAll:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .professionalPackages {
    .containerGridAll {
      padding: 0 0px;
      .containerImgRight {
        img {
          min-height: 100%;
          object-fit: cover;
        }
      }
      .containerData {
        // padding: 36px 24px;
        min-height: 380px;
        p,
        ul {
          margin: 0;
        }
      }
      .containerHappy {
        padding: 100px 24px !important;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .professionalPackages {
    .PackagePerPerson {
      .Header {
        p {
          font-size: 17px !important;
        }
      }
      .description {
        p {
          font-size: 30px !important;
          line-height: 45px !important;
        }
      }
    }
    .containerImgRight {
      img {
        min-height: 280px;
      }
    }
    .containerData {
      & > .Header {
        p {
          font-size: 24px !important;
        }
      }
      .description {
        p {
          font-size: 18px !important;
        }
      }
    }
  }
}
html[lang="ar"] {
  .professionalPackages {
    .containerData {
      text-align: right;
      direction: rtl;
      li {
        margin-right: 20px;
        position: relative;
      }
      li:before {
        position: absolute;
        right: -30px;
        top: 10px;
      }
    }
    // .PackagePerPerson{
    //     .description{
    //         & > div{
    //             justify-content: space-around
    //         }
    //     }
    // }
    .header2 {
      p {
        text-align: right !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .professionalPackages {
    padding-bottom: 80px;
    .containerGridAll {
      margin-bottom: 50px;
      padding: 0 0px;
      .containerData {
        padding: 75px 32px 70px;

        .description {
          margin-bottom: 50px;
        }
        .PackagePerPerson {
          .Header {
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
