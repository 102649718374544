.meet h4 {
    font-size: 40px;
    line-height: 55px;
    font-family: "D-DIN-Bold";
    text-transform: uppercase;
    color: #4a6ee8;
    text-align: center;
    margin: 50px 0 50px 0;
}



.carousel {
    position: relative;
    width: 400px;
    height: 400px;
    margin: auto;
    perspective: 1000px;
}

.carousel-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-250px);
    transition: transform 1s;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backface-visibility: hidden;
    transition: transform 1s ease-in-out;
    opacity: 0.9;
}

.carousel-item.active {
    opacity: 1;
}

.carousel-item img {
    width: 100%;
    height: auto;
}

.carousel-title {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.carousel-title h3 {
    font-family: "Gotham-Bold";
    font-size: 36px;
    color: #ff173d;
    margin: 0;
}

.carousel-title img {
    width: 30px;
    height: 30px;
}

.carousel-description {
    margin-top: 15px;
    font-family: "Gotham";
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 50px;
}



.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
}

.carousel-control.prev {
    left: 0;
}

.carousel-control.next {
    right: 0;
}

@media (max-width: 1200px) {
    .meet h4 {
        font-size: 28px;
        line-height: 36px;
    }

    .carousel {
        width: 200px;
        height: 200px;
    }

    .carousel-title {
        gap: 6px;
    }

    .carousel-title h3 {
        font-size: 28px;
    }

    .carousel-title img {
        width: 22px;
        height: 22px;
    }
}

@media (max-width: 668px) {
    .carousel {
        width: 55%;
        height: 60vw !important;
    }

    .carousel-inner {
        height: 100%;
        transform: translateZ(-150px);
    }

    .carousel-item {
        opacity: 1;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .carousel-item.active {
        transform: translateX(0) scale(1);
    }

    .carousel-item:nth-child(2) {
        transform: translateX(-50%) scale(0.8);
    }

    .carousel-item:nth-child(3) {
        transform: translateX(50%) scale(0.8);
    }

    .carousel-description {
        font-size: 16px;
    }
}