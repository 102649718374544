@import './utils/variables/variables.scss';
@font-face {
  font-family: 'D-DIN';
  src: url(./utils/Fonts/D-DIN.eot) format('eot'),
  url(./utils/Fonts/D-DIN.svg) format('svg'),
  url(./utils/Fonts/D-DIN.ttf) format('truetype'),
  url(./utils/Fonts/D-DIN.woff) format('woff'),
  url(./utils/Fonts/D-DIN.woff2) format('woff2') {} }
@font-face {
  font-family: 'D-DIN-Bold';
  src: url(./utils/Fonts/D-DIN-Bold.eot) format('eot'),
  url(./utils/Fonts/D-DIN-Bold.svg) format('svg'),
  url(./utils/Fonts/D-DIN-Bold.ttf) format('truetype'),
  url(./utils/Fonts/D-DIN-Bold.woff) format('woff'),
  url(./utils/Fonts/D-DIN-Bold.woff2) format('woff2') {} }
@font-face {
  font-family: 'Buttercup';
  src: url(./utils/Fonts/ChinupButtercupCutecaps.eot),
  url(./utils/Fonts/ChinupButtercupCutecaps.eot) format('embedded-opentype'),
  url(./utils/Fonts/ChinupButtercupCutecaps.woff2) format('woff2'),
  url(./utils/Fonts/ChinupButtercupCutecaps.woff) format('woff'),
  url(./utils/Fonts/ChinupButtercupCutecaps.ttf) format('truetype'),
  url(./utils/Fonts/ChinupButtercupCutecaps.svg) format('svg') {}
  font-weight: 500;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Druk-Heavy';
  src: url('./utils/Fonts/Druk-HeavyItalic-Trial.otf') format('opentype');
  font-weight: 500;
  font-display: swap; }
@font-face {
  font-family: 'D-DIN-Italic';
  src: url(./utils/Fonts/D-DIN-Italic.eot) format('eot'),
  url(./utils/Fonts/D-DIN-Italic.svg) format('svg'),
  url(./utils/Fonts/D-DIN-Italic.ttf) format('truetype'),
  url(./utils/Fonts/D-DIN-Italic.woff) format('woff'),
  url(./utils/Fonts/D-DIN-Italic.woff2) format('woff2'), }
@font-face {
  font-family: 'D-DINCondensed-Bold';
  src: url(./utils/Fonts/D-DINCondensed-Bold.eot) format('eot'),
  url(./utils/Fonts/D-DINCondensed-Bold.svg) format('svg'),
  url(./utils/Fonts/D-DINCondensed-Bold.ttf) format('truetype'),
  url(./utils/Fonts/D-DINCondensed-Bold.woff) format('woff'),
  url(./utils/Fonts/D-DINCondensed-Bold.woff2) format('woff2') {} }
@font-face {
  font-family: 'D-DINExp-Bold';
  src: url(./utils/Fonts/D-DINExp-Bold.eot) format('eot'),
  url(./utils/Fonts/D-DINExp-Bold.svg) format('svg'),
  url(./utils/Fonts/D-DINExp-Bold.ttf) format('truetype'),
  url(./utils/Fonts/D-DINExp-Bold.woff) format('woff'),
  url(./utils/Fonts/D-DINExp-Bold.woff2) format('woff2') {} }
@font-face {
  font-family: 'D-DINExp-Italic';
  src: url(./utils/Fonts/D-DINExp-Italic.eot) format('eot'),
  url(./utils/Fonts/D-DINExp-Italic.svg) format('svg'),
  url(./utils/Fonts/D-DINExp-Italic.ttf) format('truetype'),
  url(./utils/Fonts/D-DINExp-Italic.woff) format('woff'),
  url(./utils/Fonts/D-DINExp-Italic.woff2) format('woff2') {} }
@font-face {
  font-family: 'D-DINExp';
  src: url(./utils/Fonts/D-DINExp.eot) format('eot'),
  url(./utils/Fonts/D-DINExp.svg) format('svg'),
  url(./utils/Fonts/D-DINExp.ttf) format('truetype'),
  url(./utils/Fonts/D-DINExp.woff) format('woff'),
  url(./utils/Fonts/D-DINExp.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-BoldCondensed';
  src: url(./utils/Fonts/FuturaBT-BoldCondensed.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-BoldCondensed.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-BoldCondensed.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-BoldCondensed.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-BoldCondensed.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-BoldItalic';
  src: url(./utils/Fonts/FuturaBT-BoldItalic.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-BoldItalic.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-BoldItalic.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-BoldItalic.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-BoldItalic.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-Book';
  src: url(./utils/Fonts/FuturaBT-Book.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-Book.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-Book.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-Book.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-Book.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-BookItalic';
  src: url(./utils/Fonts/FuturaBT-BookItalic.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-BookItalic.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-BookItalic.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-BookItalic.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-Book.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-ExtraBlack';
  src: url(./utils/Fonts/FuturaBT-ExtraBlack.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-ExtraBlack.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-ExtraBlack.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-ExtraBlack.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-ExtraBlack.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-Heavy';
  src: url(./utils/Fonts/FuturaBT-Heavy.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-Heavy.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-Heavy.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-Heavy.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-Heavy.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-HeavyItalic';
  src: url(./utils/Fonts/FuturaBT-HeavyItalic.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-HeavyItalic.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-HeavyItalic.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-HeavyItalic.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-HeavyItalic.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-Light';
  src: url(./utils/Fonts/FuturaBT-Light.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-Light.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-Light.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-Light.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-Light.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-LightCondensed';
  src: url(./utils/Fonts/FuturaBT-LightCondensed.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-LightCondensed.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-LightCondensed.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-LightCondensed.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-LightCondensed.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-LightItalic';
  src: url(./utils/Fonts/FuturaBT-LightItalic.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-LightItalic.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-LightItalic.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-LightItalic.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-LightItalic.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url(./utils/Fonts/FuturaBT-Medium.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-Medium.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-Medium.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-Medium.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-Medium.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-MediumCondensed';
  src: url(./utils/Fonts/FuturaBT-MediumCondensed.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-MediumCondensed.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-MediumCondensed.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-MediumCondensed.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-MediumCondensed.woff2) format('woff2') {} }
@font-face {
  font-family: 'FuturaBT-MediumItalic';
  src: url(./utils/Fonts/FuturaBT-MediumItalic.eot) format('eot'),
  url(./utils/Fonts/FuturaBT-MediumItalic.svg) format('svg'),
  url(./utils/Fonts/FuturaBT-MediumItalic.ttf) format('truetype'),
  url(./utils/Fonts/FuturaBT-MediumItalic.woff) format('woff'),
  url(./utils/Fonts/FuturaBT-MediumItalic.woff2) format('woff2') {} }
@font-face {
  font-family: 'Cairo-Bold';
  src: url(./utils/Fonts/Cairo-Bold.eot) format('eot'),
  url(./utils/Fonts/Cairo-Bold.svg) format('svg'),
  url(./utils/Fonts/Cairo-Bold.ttf) format('truetype'),
  url(./utils/Fonts/Cairo-Bold.woff) format('woff'),
  url(./utils/Fonts/Cairo-Bold.woff2) format('woff2') {} }
@font-face {
  font-family: 'Cairo';
  src: url(./utils/Fonts/Cairo-Regular.eot) format('eot'),
  url(./utils/Fonts/Cairo-Regular.svg) format('svg'),
  url(./utils/Fonts/Cairo-Regular.ttf) format('truetype'),
  url(./utils/Fonts/Cairo-Regular.woff) format('woff'),
  url(./utils/Fonts/Cairo-Regular.woff2) format('woff2') {} }
@font-face {
  font-family: 'Frutiger';
  src: url(./utils/Fonts/FrutigerLTArabic-55Roman.eot) format('eot'),
  url(./utils/Fonts/FrutigerLTArabic-55Roman.svg) format('svg'),
  url(./utils/Fonts/FrutigerLTArabic-55Roman.ttf) format('truetype'),
  url(./utils/Fonts/FrutigerLTArabic-55Roman.woff) format('woff'),
  url(./utils/Fonts/FrutigerLTArabic-55Roman.woff2) format('woff2') {} }
@font-face {
  font-family: 'Gotham-Bold';
  src: url(./utils/Fonts/Gotham-Bold.eot) format('eot'),
  url(./utils/Fonts/Gotham-Bold.svg) format('svg'),
  url(./utils/Fonts/Gotham-Bold.ttf) format('truetype'),
  url(./utils/Fonts/Gotham-Bold.woff) format('woff'),
  url(./utils/Fonts/Gotham-Bold.woff2) format('woff2') {} }
@font-face {
  font-family: 'Gotham';
  src: url(./utils/Fonts/Gotham-Book.eot) format('eot'),
  url(./utils/Fonts/Gotham-Book.svg) format('svg'),
  url(./utils/Fonts/Gotham-Book.ttf) format('truetype'),
  url(./utils/Fonts/Gotham-Book.woff) format('woff'),
  url(./utils/Fonts/Gotham-Book.woff2) format('woff2') {} }
@font-face {
  font-family: 'Gotham Book';
  src: url('./utils/Fonts/Gotham-Book.eot');
  src: url('./utils/Fonts/Gotham-Book.eot?#iefix') format("embedded-opentype"),
  url('./utils/Fonts/Gotham-Book.woff2') format("woff2"),
  url('./utils/Fonts/Gotham-Book.woff') format("woff"),
  url('./utils/Fonts/Gotham-Book.ttf') format("truetype"),
  url('./utils/Fonts/Gotham-Book.svg#Gotham-Book') format("svg") {}
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Gotham-Light';
  src: url(./utils/Fonts/Gotham-Light.eot) format('eot'),
  url(./utils/Fonts/Gotham-Light.svg) format('svg'),
  url(./utils/Fonts/Gotham-Light.ttf) format('truetype'),
  url(./utils/Fonts/Gotham-Light.woff) format('woff'),
  url(./utils/Fonts/Gotham-Light.woff2) format('woff2') {} }
@font-face {
  font-family: 'Gotham-Thin';
  src: url(./utils/Fonts/Gotham-Thin.eot) format('eot'),
  url(./utils/Fonts/Gotham-Thin.svg) format('svg'),
  url(./utils/Fonts/Gotham-Thin.ttf) format('truetype'),
  url(./utils/Fonts/Gotham-Thin.woff) format('woff'),
  url(./utils/Fonts/Gotham-Thin.woff2) format('woff2') {} }
@font-face {
  font-family: 'Gotham-Medium';
  src: url(./utils/Fonts/Gotham-Medium.eot) format('eot'),
  url(./utils/Fonts/Gotham-Medium.svg) format('svg'),
  url(./utils/Fonts/Gotham-Medium.ttf) format('truetype'),
  url(./utils/Fonts/Gotham-Medium.woff) format('woff'),
  url(./utils/Fonts/Gotham-Medium.woff2) format('woff2') {} }
@font-face {
    font-family: 'GE Dinar One';
    src: url(./utils/Fonts/GEDinarOne-Light.eot);
    src: url('./utils/Fonts/GEDinarOne-Light.eot?#iefix') format('embedded-opentype'),
    url('./utils/Fonts/GEDinarOne-Light.woff2') format('woff2'),
    url('./utils/Fonts/GEDinarOne-Light.woff') format('woff'),
    url('./utils/Fonts/GEDinarOne-Light.ttf') format('truetype'),
    url('./utils/Fonts/GEDinarOne-Light.svg#GEDinarOne-Light') format('svg') {}
    font-weight: 300;
    font-style: normal;
    font-display: swap; }
@font-face {
    font-family: 'GE Dinar One';
    src: url(./utils/Fonts/GEDinarOne-Medium.eot);
    src: url('./utils/Fonts/GEDinarOne-Medium.eot?#iefix') format('embedded-opentype'),
    url('./utils/Fonts/GEDinarOne-Medium.woff2') format('woff2'),
    url('./utils/Fonts/GEDinarOne-Medium.woff') format('woff'),
    url('./utils/Fonts/GEDinarOne-Medium.ttf') format('truetype'),
    url('./utils/Fonts/GEDinarOne-Medium.svg#GEDinarOne-Medium') format('svg') {}
    font-weight: 500;
    font-style: normal;
    font-display: swap; }
@font-face {
    font-family: 'GE Dinar One';
    src: url(./utils/Fonts/GEDinarOne-Bold.eot);
    src: url('./utils/Fonts/GEDinarOne-Bold.eot?#iefix') format('embedded-opentype'),
    url('./utils/Fonts/GEDinarOne-Bold.woff2') format('woff2'),
    url('./utils/Fonts/GEDinarOne-Bold.woff') format('woff'),
    url('./utils/Fonts/GEDinarOne-Bold.ttf') format('truetype'),
    url('./utils/Fonts/GEDinarOne-Bold.svg#GEDinarOne-Bold') format('svg') {}
    font-weight: bold;
    font-style: normal;
    font-display: swap; }
.DIN-regular {
  font-family: 'D-DIN' !important; }
.DIN-bold {
  font-family: 'D-DIN-Bold' !important; }
.DIN-italic {
  font-family: 'D-DIN-Italic' !important; }
.DIN-condensedBold {
  font-family: 'D-DINCondensed-Bold' !important; }
.DIN-expBold {
  font-family: 'D-DINExp-Bold' !important; }
.DIN-expItalic {
  font-family: 'D-DINExp-Italic' !important; }
.DIN-exp {
  font-family: 'D-DINExp' !important; }
.FBT-boldcondensed {
  font-family: 'FuturaBT-BoldCondensed' !important; }
.FBT-bolditalic {
  font-family: 'FuturaBT-BoldItalic' !important; }
.FBT-book {
  font-family: 'FuturaBT-Book' !important; }
.FBT-bookitalic {
  font-family: 'FuturaBT-BookItalic' !important; }
.FBT-extrablack {
  font-family: 'FuturaBT-ExtraBlack' !important; }
.FBT-heavy {
  font-family: 'FuturaBT-Heavy' !important; }
.FBT-heavyitalic {
  font-family: 'FuturaBT-HeavyItalic' !important; }
.FBT-light {
  font-family: 'FuturaBT-Light' !important; }
.FBT-lightcondensed {
  font-family: 'FuturaBT-LightCondensed' !important; }
.FBT-lightitalic {
  font-family: 'FuturaBT-LightItalic' !important; }
.FBT-medium {
  font-family: 'FuturaBT-Medium' !important; }
.FBT-mediumcondensed {
  font-family: 'FuturaBT-MediumCondensed' !important; }
.FBT-mediumitalic {
  font-family: 'FuturaBT-MediumItalic' !important; }
.Cairo-bold {
  font-family: 'Cairo-Bold' !important; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0 !important;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root {
    min-height: inherit;
    >div {
      min-height: inherit;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto 1fr auto; } } }

img {
  max-width: 100%; }











