@import "../../../../utils/variables/variables.scss";

.santaContainer {
  margin-top: 100px;

  .santaSectionText {
    width: 90%;
    font-size: 18px;
    letter-spacing: 1px;
    text-align: initial;
    margin-bottom: 100px;

    h1 {
      color: #bd1823;
      font-family: "Buttercup";
      font-weight: 400;
      font-size: 78px;
    }

    p {
      font-family: "D-DIN";
    }
  }

  .santaSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .santaSectionContent {
      max-width: 70%;

      .santaSectionPackages {
        position: relative;
        display: flex;
        width: 90%;

        .santaSectionPackagesOffers {
          width: 100%;
          border: 1px solid #d7dce0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px;

          a {
            font-family: $DIN-bold;
            text-decoration: none;
            color: $white;
            background-color: $mainRed;
            display: inline-block;
            text-align: center;
            width: 100%;
            padding: 12px;
            border-radius: $Radius;
            position: relative;
            z-index: 1;

            // border: 1px solid #bd1823;
            // text-decoration: none;
            // font-family: "D-DIN-Bold" !important;
            // padding: 10px;
            // text-align: center;
            // min-width: 75%;
          }

          h4 {
            text-align: center;
            font-family: "D-DIN-Bold";
            margin: 0;
          }

          h3 {
            font-family: "D-DINCondensed-Bold";
            font-size: 22px;
            margin-top: 5px;
            margin-bottom: 15px;
            color: #a3843b;
          }

          a,
          a:active,
          a:focus {
            font-family: $DIN-bold;
            text-decoration: none;
            color: $white;
            background-color: $mainRed;
            display: inline-block;
            text-align: center;
            width: 100%;
            padding: 12px;
            border-radius: $Radius;
            position: relative;
            z-index: 1;

            // font-family: "D-DIN-Bold";
            // background-color: white;
            // border: 1px solid #bd1823;
            // color: #bd1823;
            // padding:10px 30px 10px 30px;
            border-radius: 5px;
          }

          a:hover {
            cursor: pointer;
            background-color: #bd1823;
            color: white;
          }
        }

        // .santaSectionPackagesOffers:last-child {
        //   padding: 0;
        //   display: flex;
        //   justify-content: flex-start;
        //   align-items: none;
        //
        //   .bestValue {
        //     width: 100%;
        //     height: 20px;
        //     border-top-right-radius: 5px;
        //     border-top-left-radius: 5px;
        //     font-size: 12px;
        //     color: white;
        //     background-color: #bd1823;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     margin-top: -20px;
        //     margin-bottom: 20px;
        //
        //     p {
        //       font-family: "D-DIN-Bold";
        //     }
        //
        //     @media screen and (max-width: 767px) {
        //       height: 25px;
        //       margin-bottom: 12px;
        //     }
        //   }
        //
        //   @media screen and (max-width: 767px) {
        //     padding-bottom: 20px;
        //   }
        // }
      }

      .compare {
        width: 90%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        padding-bottom: 100px;

        a {
          color: #bd1823;
          font-family: "D-DIN-Bold";
        }
      }
    }
  }

  .santaSection[dir="rtl"] {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .santaSectionImage {
      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }

    .santaSectionContent {
      max-width: 70%;

      .santaSectionPackages {
        position: relative;
        display: flex;
        width: 90%;

        .santaSectionPackagesOffers {
          width: 100%;
          border: 1px solid #d7dce0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px;

          a {
            font-family: $DIN-bold;
            text-decoration: none;
            color: $white;
            background-color: $mainRed;
            display: inline-block;
            text-align: center;
            width: 100%;
            padding: 12px;
            border-radius: $Radius;
            position: relative;
            z-index: 1;
          }

          h4 {
            font-family: "D-DIN-Bold";
            margin: 0;
          }

          h3 {
            font-family: "D-DINCondensed-Bold";
            font-size: 22px;
            margin-top: 5px;
            margin-bottom: 15px;
            color: #a3843b;
          }

          a,
          a:active,
          a:focus {
            font-family: $DIN-bold;
            text-decoration: none;
            color: $white;
            background-color: $mainRed;
            display: inline-block;
            text-align: center;
            width: 100%;
            padding: 12px;
            border-radius: $Radius;
            position: relative;
            z-index: 1;
            // padding:10px 30px 10px 30px;
            border-radius: 5px;
          }

          a:hover {
            cursor: pointer;
            background-color: #bd1823;
            color: white;
          }
        }

        .santaSectionPackagesOffers:last-child {
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: none;

          .bestValue {
            width: 100%;
            height: 20px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            font-size: 12px;
            color: white;
            background-color: #bd1823;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -20px;
            margin-bottom: 20px;

            p {
              font-family: "D-DIN-Bold";
            }

            @media screen and (max-width: 767px) {
              height: 25px;
              margin-bottom: 12px;
            }
          }

          @media screen and (max-width: 767px) {
            padding-bottom: 20px;
          }
        }
      }

      .compare {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        padding-bottom: 100px;

        a {
          color: #bd1823;
          font-family: "D-DIN-Bold";
        }
      }
    }
  }
}

@media only screen and (max-width: 1078px) {
  .santaContainer {
    margin-top: 20px;

    .santaSectionText {
      width: 100%;
      padding: 40px;
      margin-bottom: 0;

      h1 {
        font-size: 52px;
      }

      p {
        font-size: 14px;
      }
    }

    .santaSection {
      align-items: flex-start;

      .santaSectionImage {
        img {
          top: 50px;
          position: relative;
          z-index: 100;
          transform: scale(1.5);
          margin-left: 20px;
        }
      }

      .santaSectionContent {
        width: 80%;
        min-width: 70%;

        .santaSectionPackages {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .santaSectionPackagesOffers {
            h4 {
              font-size: 16px;
            }

            h3 {
              font-size: 18px;
            }

            a {
              font-size: 12px;
            }
          }
        }

        .compare {
          margin-top: 20px;
          display: block;
          justify-content: center;
          padding-bottom: 50px;
          width: 90%;
          text-align: center;

          a {
            color: #bd1823;
            font-size: 14px;
            font-family: "D-DIN-Bold";
          }
        }
      }
    }
  }
}
