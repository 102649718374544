@import "../../../../utils//variables/variables.scss";

.containerExperience {
  margin: 100px 0 30px 0;

  .containerData {
    margin-bottom: 66px;

    .containerHeader {
      p {
        margin: 0;
        color: $mainBlue;
        font-size: 25px;
        line-height: 30px;
        font-family: "Buttercup" !important;
        font-weight: 400;

      }
    }

    .containerDescription {
      font-family: $DIN-regular;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 26px;
    }
  }

  .containerVideo {
    margin-bottom: 66px;
  }
}

@media only screen and (min-width: 1280px) {
  .containerExperience {
    .containerData {
      .containerHeader {
        p {
          color: $mainBlue;
          font-size: 44px;
          line-height: 60px;
          font-family: "D-DIN-Bold";
        }
      }
    }

    .containerVideo {
      max-height: 376px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .containerExperience {
    .containerData {
      text-align: center;
    }

    .containerHeader {
      margin-bottom: 20px;
    }
  }
}

html[lang="ar"] {
  .containerData {
    text-align: right;

    .containerDescription {
      margin-top: 10px;
      font-family: $GE;
    }
  }
}