@import "../../../utils/variables/variables.scss";

.containerVideoSchool {
  margin-top: 85px;
  margin-bottom: 60px;
  .placeHolder {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
    border-radius: $Radius;
  }
  .containerData {
    margin-bottom: 35px;
    .containerHeader {
      text-align: center;
      p {
        margin: 0;
        color: $subColorBlue;
        font-size: 24px;
        line-height: 36px;
        font-family: "D-DIN-Bold";
        // text-decoration: underline;
        border-bottom: solid;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        padding-bottom: 20px;
        // padding: 5px 25px;
        position: relative;
      }
      p:after {
        content: "";
        background: url(../../../img/eclipse.svg);
        position: absolute;
        display: block;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: -18px;
        left: calc(50% - 18px);
        background-color: #fff;
        border-radius: 50%;
      }
    }
    .containerDescription {
      font-family: $DIN-regular;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 26px;
    }
  }
  .containerVideo {
    margin-bottom: 66px;
  }
}
.wrap {
  position: relative;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1280px) {
  .containerVideoSchool {
    .containerData {
      .containerHeader {
        p {
          color: $subColorBlue;
          font-size: 44px;
          line-height: 60px;
          font-family: "D-DIN-Bold";
        }
      }
    }
    .containerVideo {
      max-height: 376px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 768px) {
  .containerVideoSchool {
    .containerData {
      text-align: center;
      margin-bottom: 0 !important;
    }
    .containerHeader {
      margin-bottom: 20px;
    }
  }
}
html[lang="ar"] {
  .containerHeader {
    p {
      font-family: $GE !important;
      font-weight: bold;
    }
  }
}
