@import "../../utils/variables/variables.scss";

.pricing {
  overflow-x: hidden;
  .penguinsDesc {
    margin-bottom: 75px;
    h1 {
      font-family: "D-DIN-Bold";
      font-size: 45px;
      line-height: 60px;
      color: $mainBlue;
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 0 0;
      padding-bottom: 10px;
    }
    p {
      font-family: $DIN-regular;
      font-size: 20px;
      line-height: 26px;
      color: $mainDarkGray;
      text-align: center;
      margin: 0;
    }
  }
  .ticketsPenguins {
    margin-bottom: 10px;
    .cardsWrap {
      .card {
        text-align: center;
        & > div {
          background-color: $subColorBlue;
          padding: 50px 25px 70px 25px;
          border-radius: $Radius;
          -webkit-box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          -moz-box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          box-shadow: 5px 8px 10px 0px rgba(48, 48, 48, 0.1);
          height: 100%;
          position: relative;
        }
        & > div:after {
          content: "";
          background-image: url(../../img/cardpeng.png);
          display: block;
          width: 100%;
          height: 85px;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .title {
          font-size: 32px;
          margin: 0;
          line-height: 43px;
          color: $subColorBlue;
          text-transform: uppercase;
          font-family: $FBT-heavy;
        }
        .age {
          font-size: 20px;
          margin: 0;
          line-height: 26px;
          font-family: $DIN-regular;
          color: $mainDarkGray;
        }
        .price {
          font-size: 55px;
          margin: 20px 0 20px 0;
          line-height: 60px;
          font-family: $FBT-heavy;
          color: $white;
          .egp {
            font-size: 20px;
            margin-left: -10px;
            color: $white;
          }
          .old {
            font-size: 35px;
            color: rgba(255, 255, 255, 0.5);
            text-decoration: line-through;
            transform: translateX(-5px);
            display: inline-block;
          }
        }
        .description {
          font-size: 16px;
          margin: 0;
          line-height: 20px;
          font-family: $DIN-regular;
          color: $white;
          ul {
            list-style: none;
            margin: 5px 0 15px 0;
            padding: 0;
            li {
              margin-top: 24px;
            }
          }
          span {
            font-family: $DIN-bold;
            display: inline-block;
            transform: translateY(15px);
          }
        }
        .book {
          display: inline-block;
          width: 100%;
          background: $white;
          border-radius: $Radius;
          color: $mainBlue;
          text-decoration: none;
          font-family: $DIN-bold;
          //Coming soon
          padding: 18px 0;
          font-size: 16px;
          //Coming soon
          outline: none;
          border: none;
          margin-top: 8vh;
        }
        .note {
          font-family: $DIN-regular;
          font-size: 14px;
          margin: 15px 0 0 0;
          line-height: 16px;
          color: $mainRed;
        }
      }
      .card:nth-child(2) {
        & > div {
          background-color: $subColorBlue;
          //   & * {
          //     color: $white;
          //   }
        }
        // .book {
        //   background-color: $white;
        //   color: $subColorBlue;
        // }
      }
      .card:first-child,
      .card:last-child {
        position: relative;
      }
      .card:first-child:after,
      .card:last-child:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 174px;
        height: 195px;
        background-image: url(../../img/circle.png);
        background-repeat: no-repeat;
      }
    }
    .ticketsTitles {
      margin-top: 65px;
      h4 {
        font-size: 40px;
        line-height: 55px;
        font-family: "D-DIN-Bold";
        text-transform: uppercase;
        color: $subColorBlue;
        text-align: center;
        margin: 85px 0 30px 0;
        width: fit-content;
        width: -moz-fit-content;
        margin: 40px auto;
      }
    }
    .meet {
      h4 {
        font-size: 40px;
        line-height: 55px;
        font-family: "D-DIN-Bold";
        text-transform: uppercase;
        color: $subColorBlue;
        text-align: center;
        margin: 50px 0 50px 0;
      }
    }
    .sultanWrapper {
      margin-bottom: 50px;
      .follow {
        margin-top: 0px;
        text-align: left;
        font-family: $DIN-regular;
        font-size: 22px;
        margin-top: 45px;
        color: $mainDarkGray;
        text-transform: capitalize;
        span {
          margin-left: 10px;
          img {
            margin-right: 10px;
            width: 18px;
            transition: all ease 0.3s;
          }
          img:hover {
            transform: scale(1.25);
          }
        }
      }
      .sultan {
        text-align: left;
        font-family: $FBT-heavy;
        font-size: 36px;
        color: $mainRed;
        text-transform: uppercase;
        margin: 20px 0 20px 0;
      }
      .sultanIconsWrap {
        > div {
          text-align: center;
          > img {
            width: 365px;
          }
        }
      }
      .sultanexert {
        font-family: $DIN-regular;
        font-size: 18px;
        line-height: 24px;
      }
      .sultanPeng {
        font-size: 18px;
        line-height: 26px;
        font-family: $FBT-heavy;
        text-transform: uppercase;
        color: $subColorBlue;
        margin: 10px 0;
        span {
          color: $mainDarkGray;
          color: $mainDarkGray;
          font-size: 16px !important;
          transform: translateY(-5px);
          display: inline-block;
        }
      }
      .sultanPeng:last-child {
        // text-align: right;
      }
      .sultanSocial {
        margin-top: 20px;
        svg {
          font-size: 26px;
          color: $subColorBlue;
        }
      }
    }
    .penguinSlide img {
      border-radius: $Radius;
    }
    h5 {
      font-size: 22px;
      line-height: 35px;
      font-family: $DIN-regular;
      text-transform: capitalize;
      color: $subColorBlue;
      margin: 15px 0 10px 0;
      text-align: left;
    }
    .sliderItem {
      font-size: 18px;
      line-height: 24px;
      font-family: $DIN-regular;
      color: $subColorBlue;
      margin: 0;
      text-align: left;
      span {
        color: $mainDarkGray;
        transform: translateY(-5px);
        display: inline-block;
      }
    }
    .card {
      &:after {
        display: none;
      }
    }
    .card:first-child .title {
      color: $subColorBlue;
    }
  }
  .extras {
    h2 {
      font-size: 45px !important;
      line-height: 60px !important;
      font-family: "D-DIN-Bold" !important;
      text-transform: uppercase;
      color: $mainRed;
      text-align: center;
    }
    .extraWrap {
      background-color: #fcfcfc;
      padding: 5px 15px;
      margin-bottom: 24px;
      .title {
        font-family: $DIN-bold;
        font-size: 24px;
        line-height: 20px;
        margin: 0;
        color: $mainDarkGray;
      }
      .price {
        font-family: $FBT-heavy;
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        color: $mainDarkGray;
        span {
          font-size: 20px;
          line-height: 27px;
          color: $mainRed;
        }
      }
    }
  }
  .video {
    margin: 50px 0 0;
    padding: 100px 0;
    background-color: $AnotherSubGray;
    h4 {
      font-family: "D-DIN-Bold";
      font-size: 26px;
      color: $mainDarkGray;
      line-height: 35px;
      margin: 0;
    }
    h2 {
      font-family: "D-DIN-Bold";
      font-size: 38px;
      color: $subColorBlue;
      line-height: 60px;
      margin: 0 0 15px;
    }
    p {
      font-family: $DIN-regular;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 18px;
      margin: 0;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .placeHolder {
      transition: ease all 0.3s;
      object-fit: cover;
      border-radius: $Radius;
    }
    // &:hover .placeHolder {
    //     transform: scale(1.01);
    //     transform-origin: center;
    // }
    iframe {
      border-radius: $Radius;
    }
  }
  .trainers {
    .penguinSlide {
      h5 {
        color: $subColorBlue;
      }
      .sliderItem {
        color: $subColorBlue;
      }
    }
  }
}
.penguinWrap {
  // background-color: $AnotherSubGray;
  padding-top: 20px;
  padding-bottom: 25px;
}
.ticketsWrap {
  // background-color: $AnotherSubGray;
  padding: 20px 0 100px 0;
  margin-top: 30px;
}
.meetTrainersWrap {
  background-color: $AnotherSubGray;
  padding: 5px 0 40px 0;
  .teamContainer {
    margin-bottom: 50px;
  }
  .foreign {
    img {
      max-height: 435px;
    }
    .wrap {
      position: relative;
      h5 {
        transform: translateY(-5px);
        position: relative;
        left: 24px;
        margin-top: -90px;
        margin-bottom: 0;
        z-index: 9;
      }
      .sliderItem {
        margin-left: 25px;
        z-index: 9;
        font-family: $DIN-regular !important;
      }
    }
    .wrap:after {
      content: "";
      display: block;
      width: 92%;
      height: 85px;
      position: absolute;
      bottom: -8px;
      left: 10px;
      border-radius: $Radius;
      background-color: $white;
    }
  }
}
.tickets {
  h5 {
    text-align: center !important;
    color: $white !important;
  }
}
@media (min-width: 1200px) {
  .pricing {
    .titleMobile {
      display: none;
    }
    .extras {
      .price {
        text-align: right;
      }
    }
    .egyptian {
      img {
        max-height: 350px;
        object-fit: cover;
      }
    }
    .meetSection {
      .penguinSlide {
        img {
          max-height: 290px;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .pricing {
    .ticketsPenguins {
      h4 {
        font-size: 28px !important;
        line-height: 36px !important;
      }
      h4.price {
        font-size: 40px !important;
        line-height: 30px !important;
      }
      // .ticketsTitles h4{
      // margin: 80px 0 35px 0;
      // }
      .card {
        .title {
          font-size: 24px !important;
          line-height: 28px !important;
        }
      }
    }
    // .meet{
    //     h4{
    //         margin-top: 100px !important;
    //     }
    // }
    .sultanWrapper {
      .sultan {
        font-size: 28px !important;
        text-align: center !important;
        .sultanIconsWrap {
          justify-content: center !important;
        }
      }
    }
    .penguinsDesc {
      h1 {
        font-size: 28px;
        line-height: 38px;
      }
    }
    .video {
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
}
html[lang="ar"] {
  .video {
    text-align: right;
  }
  .follow {
    font-family: $GE !important;
    direction: rtl;
    display: flex;
  }
  .sultan {
    text-align: right !important;
  }
  .ticketsPenguins {
    .meetSection .sliderItem:last-child {
      text-align: right !important;
      padding: 0 15px;
    }
    h5 {
      // text-align: right !important;
      margin-right: 0px;
    }
    .price {
      direction: rtl;
    }
    .description {
      font-family: $GE !important;
    }
  }
  .foreign {
    .wrap {
      h5 {
        left: auto !important;
        text-align: center !important;
      }
    }
  }
  .egyptian {
    span {
      text-align: center;
      width: 100%;
    }
  }
  .sultanexert {
    text-align: right;
    direction: rtl;
  }
  .penguinsDesc {
    p {
      direction: rtl;
    }
  }
}
