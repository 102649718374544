@import "../../utils/variables/variables.scss";
.professionals {
  overflow-x: hidden;
  .start {
    font-size: 32px;
    font-family: $FBT-medium;
    color: #1a1a1a;
    line-height: 42px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 57px;
    text-transform: capitalize;
  }
  .date {
    text-align: center;
    font-size: 32px;
    font-family: $FBT-heavy;
    color: $subColorBlue;
    line-height: 42px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 88px;
    text-transform: uppercase;
  }
  .bgColor {
    background: rgb(74, 110, 232);
    background: linear-gradient(270deg, rgba(20, 43, 115, 1) 0%, rgba(74, 110, 232, 1) 100%);
    position: relative;
    color: $white;
    padding: 117px 0;
    text-align: center;
    text-transform: uppercase;
    p:first-child {
      font-size: 26px;
      font-family: $FBT-medium;
      line-height: 35px;
      padding: 0;
      margin: 0;
    }
    p:last-child {
      font-size: 45px;
      font-family: $FBT-heavy;
      line-height: 60px;
      padding: 0;
      margin: 0;
    }
  }
  .bgColor::after {
    position: absolute;
    content: "";
    background: url("../../img/mask.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
  }
}
.professionalPackages {
  margin-top: 120px;
  text-align: center;

  h2 {
    font-size: 45px;
    font-family: $DIN-bold;
    color: $mainBlue;
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-size: 26px;
    font-family: $FBT-medium;
    color: $subColorBlue;
    text-transform: uppercase;
  }
  p {
    font-size: 20px;
    font-family: $DIN-regular;
    color: $mainDarkGray;
    line-height: 26px;
    padding-bottom: 50px;
    margin: 0;
    padding-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .professionalPackages {
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 25px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }
}
