@import "../../../utils/variables/variables.scss";
.selectContainer {
  position: relative;

  input {
    background-color: transparent;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 12px;
    transform: translateX(-7px);
  }

  .containerOptions {
    list-style: none;
    padding-inline-start: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    overflow: auto;
    max-height: 200px;
    transform: scale(0);
    transform-origin: top left;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    padding-left: 5px;
    transition: all 0.3s;

    &.open {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }

    li {
      cursor: pointer;
      margin: 10px 5px;
      font-size: 13px;
    }
  }
}
html[lang="ar"] {
  .selectContainer {
    input {
      line-height: 15px;
    }
    li {
      font-family: $GE;
    }
  }
}
