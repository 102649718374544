@import "../../../utils/variables/variables.scss";

.containerHeaderPanel {
  font-family: "Buttercup" !important;
  // max-height: 671px;
  font-weight: 400;
  position: relative;

  // top: 15px;
  .containerTitel {
    background-color: $subwhite;
    padding: 25px 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 2px 2px 2px #cccccc69;
    position: relative;

    p {
      margin: 0;
    }

    h1 {
      margin: 0;
      font-family: "Buttercup" !important;
      font-weight: 400;
    }

    .subTitle {

      color: $subColorBlue;
      font-size: 16px;
      font-family: "Buttercup" !important;
      line-height: 21px;
      font-weight: 400;

    }

    .title {
      color: $mainBlue;
      font-size: 35px;
      font-family: "Buttercup" !important;
      font-weight: 400;

      line-height: 50px;
    }

    .description {
      color: $mainDarkGray;
      font-size: 15px;
      font-family: $DIN-regular;
      line-height: 18px;
      margin-top: 5px;
    }
  }
}

.positionAbsolute {
  position: absolute;
  z-index: 99;
  top: -10px;
  left: 0;

  h2 {
    letter-spacing: 4px !important;
    font-family: "Buttercup" !important;
    position: absolute;
    font-weight: 400;

    color: #a3843b;
    left: 50px;
  }

  .cmlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;

    img {
      width: 20%;
    }
  }
}

.positionAbsoluteFoot {
  position: absolute;
  left: 0;
  bottom: -5px;
}

.relativePos {
  position: relative;
}

.containerTextCenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    color: $white;
    font-size: 30px;
    font-family: "Buttercup" !important;
    font-weight: 400;

    line-height: 40px;
    text-align: center;
  }
}

.containerTextCenter p {
  font-family: $Cairo-bold !important;
}

@media only screen and (max-width: 620px) {
  .containerHeaderPanel {
    .containerTitel {
      margin-top: -10px;
    }

    .containerTextCenter {
      .title {
        font-size: 26px;
      }

      .titleCentered {
        transform: translateY(-80px);
        font-size: 40px !important;
        font-weight: 900 !important;
      }
    }

    .containerPanal {
      img {
        // object-fit: cover;
        object-fit: contain;
        height: auto;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .containerHeaderPanel {
    .containerTitel {
      padding: 50px 32px;
      margin-top: -120px;

      .subTitle {
        font-size: 26px;
        line-height: 31px;
      }

      .title {
        font-size: 45px;
        line-height: 60px;
      }

      .description {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .containerTextCenter {
    .title {
      font-size: 45px;
      line-height: 60px;
    }

    .titleCentered {
      transform: translateY(-40px);
      font-size: 60px !important;
      font-weight: 900 !important;
    }
  }
}

html[lang="ar"] {
  .containerTitel {
    text-align: right;
  }

  .description {
    direction: rtl;
  }
}